import classNames from "classnames"
import React, { PropsWithChildren } from "react"

import { Color } from "../../../constants/V2/color"
import Section from "../../elements/V2/Section"

import {
  getBackgroundColorClass,
  getGradientFromColorClass,
  getGradientToColorClass,
} from "@utils/V2/color"

type Props = {
  id?: string
  primaryBackgroundColor: Color
  secondaryBackgroundColor?: Color
  className?: string
} & React.DOMAttributes<HTMLDivElement>

const LayoutSection = ({
  id,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  children,
  className,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Section
      id={id}
      className={classNames(
        "py-3xl-v2 -mb-lg-v2 rounded-t-lg-v2 relative",
        !secondaryBackgroundColor
          ? getBackgroundColorClass(primaryBackgroundColor)
          : [
              "bg-gradient-to-br",
              getGradientFromColorClass(primaryBackgroundColor),
              getGradientToColorClass(secondaryBackgroundColor),
            ],
        className
      )}
      {...props}
    >
      {children}
    </Section>
  )
}

export default LayoutSection
