import { useState, useEffect, useCallback, useRef } from "react"

export const useElementObserver = (
  targetRef: {
    current: HTMLDivElement | null
  },
  options?: {
    intersectionOffset?: number
    threshhold?: number
  }
) => {
  const [isVisible, setIsVisible] = useState<boolean>()
  const observerRef = useRef<IntersectionObserver>()
  const startObservingIntervalId = useRef<NodeJS.Timeout>()
  const alreadyObserving = useRef<boolean>()

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: `${options?.intersectionOffset || 0}px`,
      threshold: options?.threshhold || 0,
    }

    observerRef.current = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            setIsVisible(true)
          } else {
            setIsVisible(false)
          }
        })
      },
      observerOptions
    )

    return () => {
      if (observerRef.current) {
        observerRef.current?.disconnect()
      }

      if (startObservingIntervalId.current) {
        clearInterval(startObservingIntervalId.current)
      }
    }
  }, [targetRef, options, observerRef])

  const startObserving = useCallback(() => {
    if (alreadyObserving.current) {
      return
    }

    startObservingIntervalId.current = setInterval(() => {
      if (targetRef && targetRef.current && observerRef.current) {
        observerRef.current.observe(targetRef.current)
        alreadyObserving.current = true
        clearInterval(startObservingIntervalId.current)
      }
    }, 100)
  }, [observerRef, targetRef])

  return { isVisible, startObserving }
}
