import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import CircleExclamationIcon from "../Icons/CircleExclamationIcon"
import Typography from "../Typography"

interface Props {
  error: string
  className?: string
}

const InputError = ({ error, className, ...props }: Props) => {
  return (
    <div className="mt-[5px] flex gap-[5px] text-center">
      <CircleExclamationIcon className="text-orange-v2" />
      <Typography
        color={Color.Orange}
        text={error}
        font="grotesk"
        size="body-sm"
        weight="book"
        className={classNames("pt-[1px]", className)}
        {...props}
      />
    </div>
  )
}

export default InputError
