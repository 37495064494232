//TODO consider removing this component and just using Typography throughout site due to differing product price types
//e.g custom pricing
import classNames from "classnames"
import React from "react"

import Typography from "./Typography"

import { Color } from "constants/V2/color"

export type NumberDisplayAdornmentPosition = "right" | "left"

export type NumberDisplayValueProps = {
  superscriptText?: string
  sideScriptText?: string
  subscriptText?: string
  value?: string | number
  adornmentText: string
}

type NumberDisplayProps = {
  type: "price" | "percentage"
  textColor: Color
  strikeSuperscriptText?: boolean
  size: "small" | "large"
  enableThousandSeparator?: boolean
  adornmentTextPosition: NumberDisplayAdornmentPosition
  headingBodyText?: string
  alignment?: "center" | "left"
} & NumberDisplayValueProps

const NumberDisplay = ({
  value,
  textColor,
  superscriptText,
  strikeSuperscriptText,
  subscriptText,
  size,
  type,
  adornmentTextPosition,
  adornmentText,
  enableThousandSeparator,
  sideScriptText,
  alignment = "left",
}: NumberDisplayProps) => {
  const isAdornmentTextRight =
    type === "percentage" || adornmentTextPosition === "right"

  return (
    <div>
      {superscriptText && (
        <div className="flex gap-4-v2">
          <Typography
            weight="medium"
            size="body-md"
            font="grotesk"
            color={textColor}
            text={superscriptText?.split(" ")[0]}
            leadingNone
          />
          <Typography
            weight="medium"
            size="body-md"
            font="grotesk"
            color={textColor}
            text={superscriptText?.split(" ").slice(1).join(" ")}
            leadingNone
            className={classNames({
              "line-through": strikeSuperscriptText,
            })}
          />
        </div>
      )}

      <div className="flex items-end gap-4-v2">
        <div
          className={classNames("flex", {
            "flex-row-reverse justify-end": isAdornmentTextRight,
            "mx-auto": alignment === "center",
          })}
        >
          {/* The isNan check is a patch */}
          {value !== undefined &&
            (typeof value === "number" || !isNaN(Number(value))) &&
            value !== 0 && (
              <Typography
                font="grotesk"
                weight="medium"
                color={textColor}
                text={type === "price" ? adornmentText : "%"}
                size={
                  isAdornmentTextRight
                    ? size === "large"
                      ? "h2"
                      : "h5"
                    : size === "large"
                      ? "lead-lg"
                      : "lead-md"
                }
                className={classNames({
                  "pr-[2px] pt-4-v2": !isAdornmentTextRight && size === "large",
                  "pr-[2px] pt-[2px]":
                    !isAdornmentTextRight && size === "small",
                  "!text-h5-v2": isAdornmentTextRight && size === "small",
                })}
              />
            )}

          <Typography
            size={size === "large" ? "h2" : "h5"}
            font="grotesk"
            weight="medium"
            color={textColor}
            text={
              typeof value === "number"
                ? type === "price" && enableThousandSeparator
                  ? value.toLocaleString("en-US")
                  : value.toString()
                : typeof value === "string"
                  ? value
                  : "TBA"
            }
            className={classNames({
              "!text-h5-v2": size === "small",
            })}
          />
        </div>

        {sideScriptText && (
          <Typography
            font="grotesk"
            size="body-sm"
            weight="medium"
            className="pb-10-v2"
            color={textColor}
            text={sideScriptText}
          />
        )}
      </div>

      {subscriptText && (
        <Typography
          weight="book"
          font="grotesk"
          size="body-sm"
          color={textColor}
          text={subscriptText}
          className={classNames("-mt-8-v2 lg-v2:mt-0", {
            "text-center": alignment === "center",
            "opacity-50": size === "small",
          })}
        />
      )}
    </div>
  )
}

export default NumberDisplay
