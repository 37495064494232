import classNames from "classnames"
import React from "react"

type Props = {
  className?: string
  alt?: string
}

const Checkmark = ({ className, alt }: Props) => {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 11 8"
      aria-labelledby={alt || ""}
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("group", className)}
    >
      <g width="11" height="8">
        <path d="M1 3.2L4.8 7L10.8 1" />
      </g>
    </svg>
  )
}

export default Checkmark
