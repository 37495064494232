import Player from "@vimeo/player"
import classNames from "classnames"
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react"

import GatsbyStoryblokImage from "./GatsbyStoryblokImage"
import { getGatsbyImage } from "../../../../plugins/storyblok-image-plugin/src"

import { isMobile } from "@utils/V2/screen"

interface DefaultVideoProps {
  video: string
  coverImage: string
  ImageAlt?: string
  width?: number
  loadType?: "eager" | "lazy"
  rounded?: boolean
  aspectRatio: "1:1" | "16:9" | "3:4" | "4:3" | "fullWidth"
  playOnMobile?: boolean
  className?: string
}

interface AutoplayVideoProps extends DefaultVideoProps {
  playbackMode: "autoplay"
  pause?: boolean
  reset?: boolean
  preventLoop?: boolean
}

interface HoverVideoProps extends DefaultVideoProps {
  playbackMode: "hover"
  /*
    Note: The following prop should only be used in the case where
    Video is being used as a background to content and playback mode
    is "hover".

    The prop will allow for an external state to dictate whether the
    Video is being hovered or not.
  */
  videoContainerHover?: boolean
  preventLoop?: boolean
}

interface ManualVideoProps extends DefaultVideoProps {
  playbackMode: "manual"
  preventLoop?: undefined
}

type Props = AutoplayVideoProps | HoverVideoProps | ManualVideoProps

const Video = ({
  video,
  coverImage,
  ImageAlt,
  width,
  loadType,
  rounded,
  aspectRatio,
  playbackMode,
  className,
  playOnMobile = true,
  preventLoop,
  ...props
}: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [playing, setPlaying] = useState(false)
  const vimeoPlayer = useRef<Player | null>(null)
  const [useFallbackImage, setUseFallbackImage] = useState(true)

  useEffect(() => {
    if (playOnMobile || !isMobile()) {
      setUseFallbackImage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCanPlay = useCallback(() => {
    if (playbackMode === "autoplay") {
      setPlaying(true)
      if (iframeRef?.current) {
        iframeRef.current.classList.add("opacity-100")
        setTimeout((iframeEl = iframeRef.current) => {
          iframeEl.previousElementSibling?.classList.add("!hidden")
        }, 300)
      }
    }
  }, [playbackMode])

  useEffect(() => {
    handleCanPlay()
  }, [handleCanPlay])

  const handleMouseEnter = useCallback(() => {
    if (playbackMode === "hover" && !playing) {
      if (videoRef.current) {
        videoRef.current.play()
      } else if (iframeRef.current && iframeRef.current.contentWindow) {
        if (video.includes("vimeo") && vimeoPlayer.current) {
          vimeoPlayer.current.play()
        } else {
          iframeRef.current.contentWindow.postMessage(
            '{"event":"command","func":"playVideo","args":""}',
            "*"
          )
        }
      }
      setPlaying(true)
    }
  }, [video, playbackMode, playing])

  const handleMouseLeave = useCallback(() => {
    if (playbackMode === "hover" && playing) {
      if (videoRef.current) {
        videoRef.current.pause()
      } else if (iframeRef.current && iframeRef.current.contentWindow) {
        if (video.includes("vimeo") && vimeoPlayer.current) {
          vimeoPlayer.current.pause()
        } else {
          iframeRef.current.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
          )
        }
      }
      setPlaying(false)
    }
  }, [video, playbackMode, playing])

  const getExternalVideoId = () => {
    if (video.includes("youtube")) {
      if (video.includes("/shorts/")) {
        return video.split("/shorts/")[1].split("?")[0]
      } else {
        return video.split("?v=")[1].split("&")[0]
      }
    } else if (video.includes("youtu.be") || video.includes("vimeo")) {
      return video.substring(video.lastIndexOf("/") + 1).split("&")[0]
    }
  }

  useEffect(() => {
    if ((props as HoverVideoProps).videoContainerHover === true) {
      handleMouseEnter()
    }
    if ((props as HoverVideoProps).videoContainerHover === false) {
      handleMouseLeave()
    }

    if (
      iframeRef?.current &&
      video.includes("vimeo") &&
      vimeoPlayer.current === null
    ) {
      vimeoPlayer.current = new Player(iframeRef.current)
    }
  }, [video, props, handleMouseEnter, handleMouseLeave])

  // Side effects to reset and pause video
  useLayoutEffect(() => {
    if (
      videoRef.current &&
      playbackMode === "autoplay" &&
      (props as AutoplayVideoProps).reset === true
    ) {
      const chromeAgent = window.navigator.userAgent.indexOf("Chrome") > -1
      let safariAgent = window.navigator.userAgent.indexOf("Safari") > -1

      // Discard Safari since it also matches Chrome
      if (chromeAgent && safariAgent) safariAgent = false

      // NOTE: This is a hack for safari on MacOs as setting current time doesn't work
      if (safariAgent && video.includes(".webm")) {
        videoRef.current.load()
      } else {
        videoRef.current.currentTime = 0
      }
    }

    if (
      videoRef.current &&
      playbackMode === "autoplay" &&
      (props as AutoplayVideoProps).pause === true
    ) {
      videoRef.current.pause()
    } else if (
      videoRef.current &&
      playbackMode === "autoplay" &&
      videoRef.current.readyState == 4
    ) {
      videoRef.current.play()
    }
  }, [props, playbackMode, video])

  if (useFallbackImage) {
    return (
      <GatsbyStoryblokImage
        image={coverImage}
        alt={ImageAlt || ""}
        loadType="eager"
        quality={10}
        aspectRatio={aspectRatio}
        rounded={rounded}
        className="relative w-full overflow-hidden"
      />
    )
  }

  if (
    video.includes("youtube") ||
    video.includes("youtu.be") ||
    video.includes("vimeo")
  ) {
    const videoId = getExternalVideoId()

    const url = video.includes("vimeo")
      ? `https://player.vimeo.com/video/${videoId}?playsinline=1&${
          playbackMode === "autoplay" && "&autoplay=1"
        }${
          playbackMode !== "manual" && `&controls=0&loop=1&muted=1`
        }&autopause=0`
      : `https://www.youtube.com/embed/${videoId}?playsinline=1&rel=0&version=3&playerapiid=ytplayer${
          playbackMode === "autoplay" && "&autoplay=1"
        }${
          playbackMode !== "manual" &&
          `&controls=0&enablejsapi=1&loop=1&playlist=${videoId}&mute=1`
        }`

    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={
          aspectRatio !== "fullWidth" && width
            ? {
                maxWidth: `${width}px`,
              }
            : undefined
        }
        className={classNames(
          "relative w-full overflow-hidden",
          {
            "translate-x-0 overflow-hidden rounded": rounded,
          },
          { "aspect-square": aspectRatio === "1:1" },
          { "aspect-[4/3]": aspectRatio === "4:3" },
          {
            "aspect-video":
              aspectRatio === "16:9" || aspectRatio === "fullWidth",
          },
          { "aspect-[3/4]": aspectRatio === "3:4" },
          className
        )}
      >
        {playbackMode === "autoplay" && (
          <GatsbyStoryblokImage
            image={coverImage}
            alt=""
            loadType="eager"
            quality={10}
            aspectRatio={aspectRatio}
            rounded={rounded}
          />
        )}
        <iframe
          ref={iframeRef}
          src={url}
          allow="autoplay; encrypted-media"
          allowFullScreen
          loading={loadType}
          onLoad={handleCanPlay}
          className={classNames(
            "absolute left-1/2 top-1/2 h-full -translate-x-1/2 -translate-y-1/2 object-cover object-center",
            {
              "opacity-0 transition-opacity duration-300 ease-linear":
                playbackMode === "autoplay",
            },
            playbackMode === "manual"
              ? "w-full"
              : "pointer-events-none w-[300%]"
          )}
        ></iframe>
      </div>
    )
  }

  const optimizedCoverImage = getGatsbyImage(coverImage, {
    width: 666,
    quality: 80,
    layout: "constrained",
  })

  return (
    <video
      ref={videoRef}
      width={width}
      poster={optimizedCoverImage.images.fallback?.src}
      autoPlay={playbackMode === "autoplay"}
      playsInline
      preload={loadType === "eager" ? "auto" : "none"}
      loop={playbackMode !== "manual" && preventLoop !== true}
      muted={playbackMode !== "manual"}
      controls={playbackMode === "manual"}
      onCanPlay={handleCanPlay}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={
        aspectRatio !== "fullWidth" && width
          ? {
              maxWidth: `${width}px`,
            }
          : undefined
      }
      className={classNames(
        "inline-block w-full object-cover object-center",
        {
          "translate-x-0 overflow-hidden rounded": rounded,
        },
        { "aspect-square": aspectRatio === "1:1" },
        { "aspect-[4/3]": aspectRatio === "4:3" },
        {
          "aspect-video": aspectRatio === "16:9",
        },
        { "aspect-[3/4]": aspectRatio === "3:4" },
        { "aspect-auto": aspectRatio === "fullWidth" },
        className
      )}
    >
      {/*
       * In order for videos to work correctly on Safari on MacOS BigSur and later,
       * the videos should be VP9 webm videos.
       *
       * Refer to this: https://caniuse.com/webm
       */}
      <source
        src={video}
        type={video.includes(".webm") ? "video/webm" : "video/mp4"}
      />
      Your browser does not support HTML video.
    </video>
  )
}

export default Video
