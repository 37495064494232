import React from "react"

type CircleExclamationIconProps = {
  className?: string
}

const CircleExclamationIcon = ({ className }: CircleExclamationIconProps) => {
  return (
    <svg
      fill="none"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeWidth="1.5"
        fillRule="evenodd"
        clipRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 2.25V2.25C12.7283 2.25 15.75 5.27175 15.75 9V9C15.75 12.7283 12.7283 15.75 9 15.75V15.75C5.27175 15.75 2.25 12.7283 2.25 9V9C2.25 5.27175 5.27175 2.25 9 2.25Z"
      />
      <path
        d="M9 9.375V5.625"
        strokeWidth="1.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        strokeWidth="1.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.99925 12C8.89575 12 8.81175 12.084 8.8125 12.1875C8.8125 12.291 8.8965 12.375 9 12.375C9.1035 12.375 9.1875 12.291 9.1875 12.1875C9.1875 12.084 9.1035 12 8.99925 12"
      />
    </svg>
  )
}

export default CircleExclamationIcon
