import classNames from "classnames"
import React from "react"

import Typography, { TypographySize } from "./Typography"

import { Color } from "constants/V2/color"
type Props = {
  size: "small" | "large"
  alignment?: "center" | "left"
  superscriptColor: Color
  headingTextColor: Color
  headingBodyColor: Color
} & GridSectionTitleProps

export interface GridSectionTitleProps {
  superscriptText?: string
  headingText?: string
  headingBodyText?: string
}

const SUPERSCRIPT_SIZES: Record<string, TypographySize> = {
  small: "subscript-lg",
  large: "subscript-lg",
}

const HEADING_SIZES: Record<string, TypographySize> = {
  small: "lead-lg",
  large: "h4",
}

const HEADING_BODY_SIZES: Record<string, TypographySize> = {
  small: "body-lg",
  large: "body-lg",
}

const GridSectionTitles = ({
  size,
  alignment = "center",
  superscriptText,
  superscriptColor,
  headingText,
  headingTextColor,
  headingBodyText,
  headingBodyColor,
}: Props) => {
  return (
    <div className="mb-2xl-v2">
      {superscriptText && (
        <Typography
          color={superscriptColor}
          text={superscriptText}
          font="grotesk"
          weight="book"
          size={SUPERSCRIPT_SIZES[size]}
          className={classNames(
            "mb-8-v2 text-left",
            alignment == "center" ? "sm-v2:text-center" : "sm-v2:text-left"
          )}
        />
      )}
      {headingText && (
        <Typography
          color={headingTextColor}
          text={headingText}
          font="grotesk"
          weight="medium"
          size={HEADING_SIZES[size]}
          element="h2"
          className={classNames(
            "text-left",
            alignment == "center" ? "sm-v2:text-center" : "sm-v2:text-left"
          )}
        />
      )}

      {headingBodyText && (
        <Typography
          color={headingBodyColor}
          text={headingBodyText}
          font="grotesk"
          weight="book"
          size={HEADING_BODY_SIZES[size]}
          className={classNames(
            "mt-8-v2 text-left opacity-70",
            alignment == "center" ? "sm-v2:text-center" : "sm-v2:text-left"
          )}
        />
      )}
    </div>
  )
}

export default GridSectionTitles
