import classNames from "classnames"
import React from "react"

type Props = {
  children: React.ReactNode
  isVisible?: boolean
}

const StickyContainer = ({ children, isVisible }: Props) => {
  return (
    <div
      className={classNames(
        isVisible ? "h-[calc(70px_+_env(safe-area-inset-bottom,_0px))]" : "h-0",
        "fixed z-30 w-full transition-all duration-300 bottom-0 left-0"
      )}
    >
      <div
        className={classNames(
          "bg-white px-20-v2 py-10-v2 col-span-full flex items-center pb-[calc(10px_+_env(safe-area-inset-bottom,_0px))]"
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default StickyContainer
