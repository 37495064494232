export const parseQueryString = (searchString: string): Record<any, string> => {
  const str = searchString
  const objURL: Record<any, string> = {}

  str.replace(
    new RegExp("([^?=&]+)(=([^&]*))?", "g"),
    ($0, $1, $2, $3): any => {
      objURL[$1] = $3
    }
  )

  return objURL
}

export const withPrefix = (path: string): string => {
  if (
    typeof process.env.GATSBY_PREFIX_PATH === "string" &&
    path.substring(0, process.env.GATSBY_PREFIX_PATH.length) ===
      `${process.env.GATSBY_PREFIX_PATH}`
  ) {
    return path
  } else {
    return `${process.env.GATSBY_PREFIX_PATH || ""}${path}`
  }
}
